import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'

const ProtectedRoutes = ({ isLoggedIn }) => {
  console.log(isLoggedIn)
  return isLoggedIn !== '' && isLoggedIn !== null ? <Outlet /> : <Navigate to={'/admin'} />
}

ProtectedRoutes.propTypes = {
  isLoggedIn: PropTypes.any,
}

export default React.memo(ProtectedRoutes)
