import React, { Component, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

import AdminLogin from './views/pages/AdminLogin/AdminLogin'
import { getFirestore } from 'firebase/firestore'
import './App.css'
import './scss/style.scss'
import ProtectedRoutes from './utils/ProtectedRoutes'

window.Buffer = window.Buffer || require('buffer').Buffer

const firebaseConfig = {
  apiKey: 'AIzaSyAKlMHlJ8zpLfCofzgRYoefyrpXJFv7BmE',
  authDomain: 'hopa-card-general.firebaseapp.com',
  projectId: 'hopa-card-general',
  storageBucket: 'hopa-card-general.appspot.com',
  messagingSenderId: '164519345789',
  appId: '1:164519345789:web:0f0df2234d581977bcbb14',
  measurementId: 'G-297TFZBXSN',
}

// export const timestamp = firebase.firestore.FieldValue.serverTimestamp
export const app = initializeApp(firebaseConfig)
export const authentication = getAuth(app)
export const storage = getStorage(app)
export const firestore = getFirestore(app)

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
// const Login = React.lazy(() => import('./views/pages/login/OnBoarding'))
const Login = React.lazy(() => import('./views/pages/login/OnBoardingContainer'))
// const AdminLogin = React.lazy(() => import('./views/pages/AdminLogin'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const SplashScreen = React.lazy(() => import('./views/pages/splashScreen/Success'))
const AdminPanel = React.lazy(() => import('./views/pages/adminPanel/AdminPanel'))
const LandingPage = React.lazy(() => import('./views/pages/LandingPage/landingPage'))

const App = () => {
  // const subdomain = window?.location?.hostname?.split('.')[0]
  const subdomain = window?.location?.pathname?.split('/')[1]
  const successPage = window?.location?.pathname?.split('/')[1]
  const isSubdomain =
    subdomain.match(/[a-zA-Z]/) === null || successPage === 'success' ? true : false
  const isUser = subdomain && subdomain !== 'localhost' && subdomain !== 'admin' ? true : false
  const [isLoggedIn, setIsLoggedIn] = useState('')
  const [isLoader, setIsLoader] = useState(true)
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authentication, (user) => {
      setIsLoader(false)
      if (user) {
        setIsLoggedIn(user?.uid)
      } else {
        setIsLoggedIn(null)
      }
    })
    return () => unsubscribe()
  }, [])
  if (isLoader) {
    return <p>{loading}</p>
  } else {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            {isSubdomain ? (
              <>
                {successPage === 'success' ? (
                  <Route exact path="/success" name="Splash Screen" element={<SplashScreen />} />
                ) : (
                  <Route exact path="/:id" name="Login Page" element={<Login />} />
                )}
                <Route exact path="/" name="Landing Page" element={<LandingPage />} />
                <Route exact path="/:id" name="Login Page" element={<Login />} />
              </>
            ) : (
              <>
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />
                <Route element={<ProtectedRoutes isLoggedIn={isLoggedIn} />}>
                  <Route path="*" name="Home" element={<DefaultLayout />} />
                </Route>
                <Route exact path="/admin" name="Admin Panel" element={<AdminLogin />} />
              </>
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
